<template>
  <div class="vh-100 bg-fff">
    用户名：<input type="text" v-model="username">
    <br>
    密码:
    <input type="password" v-model="password">
    <br>
    <button @click="login">
      登录
    </button>
  </div>
</template>

<script>
import { Default } from '../../apis'
import storeMixin from '../../mixins/store'
import { clearToken } from '../../utils/auth'

export default {
  mixins: [storeMixin],
  data () {
    return {
      username: '8800021716',
      password: 'ceshi666'
    }
  },
  methods: {
    login () {
      this.$http.postFormData(Default.LOGIN, {
        username: this.username,
        password: this.password,
        captNum: '',
        deviceNo: 'ylh-web-front-main',
        deviceName: 'ylh-web-front-main'
      }).then(res => {
        // 清理token
        clearToken()

        if (res.data.success) {
          this.$router.push('/home')
          this.store.commit('changeActiveMenuId', 0)
          localStorage.setItem('userpassword', JSON.stringify(this.password))
        }
      })
    }
  },
  created () {
    if (process.env.NODE_ENV === 'production') {
      window.location.href = '/home'
    }
    // 清空本地存储
    localStorage.removeItem('userInfo')
    localStorage.removeItem('sessionId')
    localStorage.removeItem('permissionList')
    localStorage.removeItem('shopInfo')
    localStorage.removeItem('userHead')
  }
}
</script>

<style lang="scss" scoped></style>
